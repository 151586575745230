var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.referralName)+" Clients")]),_c('div',{staticClass:"overflow-auto table-container"},[_c('table',{staticClass:"table table-striped mb-0 responsive-mobile-tablet-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.referralSourceClients),function(referralSourceClient){return _c('tr',{key:referralSourceClient.id},[_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("ID:")]),_vm._v(_vm._s(referralSourceClient.id)+" ")]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Name:")]),_c('router-link',{attrs:{"to":`/clients/${referralSourceClient.id}`}},[_vm._v(" "+_vm._s(referralSourceClient.name)+" ")])],1),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Created At:")]),_vm._v(_vm._s(_vm._f("date")(referralSourceClient.created_at,"YYYY-MM-DD"))+" ")]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Email:")]),_c('a',{attrs:{"href":`mailto:${referralSourceClient.email}`}},[_vm._v(_vm._s(referralSourceClient.email))])]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Phone:")]),_vm._v(" "+_vm._s(referralSourceClient.phone_number)+" ")]),_c('td',[_c('b',{staticClass:"mobile-tablet-only mr-2"},[_vm._v("Status:")]),_c('span',{class:`${
                referralSourceClient.status == 'ACTIVE' ||
                referralSourceClient.status == 'PROTECTED'
                  ? 'text-success'
                  : referralSourceClient.status == 'PENDING' ||
                    referralSourceClient.status == 'ON_HOLD'
                  ? 'text-warning'
                  : 'text-danger'
              }`},[_vm._v(" "+_vm._s(referralSourceClient.status == "PROTECTED" ? "PROTECTED ACCOUNT" : referralSourceClient.status == "ON_HOLD" ? "ON HOLD" : referralSourceClient.status))])])])}),0)]),(_vm.referralSourceClients.length == 0)?_c('div',{staticClass:"card-body pb-0"},[_c('alert',[_vm._v(" No clients ")])],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Id")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Created At")]),_c('th',[_vm._v("Email")]),_c('th',[_vm._v("Phone Number")]),_c('th',[_vm._v("Status")])])])
}]

export { render, staticRenderFns }