<template>
  <div class="container">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <h1 class="m-0 mb-2">Referral Sources</h1>
      <router-link
        to="/admin/referral-sources/create"
        class="btn btn-outline-blue btn-sm"
      >
        <icon type="plus" /> Add New Referral Source
      </router-link>
    </div>

    <alert v-if="loading" class="light-shadow" />
    <div class="card theme-card border-lg-0 overflow-auto" v-if="!loading">
      <div class="card-body px-2 px-sm-4">
        <div class="d-flex justify-content-end flex-wrap">
          <div
            class="d-flex align-items-center mr-auto text-dark col-auto mb-3"
          >
            <span>
              <b>
                <i class="fas fa-spin fa-circle-notch" v-if="busy"></i>
                {{ busy ? "" : referralSources.length }}/{{ total }}
              </b>
              Referral Source{{ loading || total != 1 ? "s" : "" }}
            </span>
          </div>
        </div>
        <div class="position-relative mb-0">
          <input
            type="search"
            class="form-control"
            @keydown.enter="searchReferrerText"
            v-model.trim="searchContent"
            placeholder="Search by First or Last Name"
          />
          <span>
            <i
              class="fa fa-search search-icon text-black"
              @click="searchReferrerText"
            ></i>
            <i
              v-if="searchContent"
              class="fas fa-times cancel-icon"
              @click="(searchContent = ''), searchReferrerText()"
            ></i>
          </span>
        </div>
      </div>
      <div class="table-container radius-0" v-if="!loading">
        <table
          class="table table-striped mb-0 responsive-mobile-tablet-table p-sm-1"
        >
          <thead>
            <tr>
              <th>id</th>
              <th>first</th>
              <th>last</th>
              <th class="text-right">show clients</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="referralSource in referralSources"
              :key="referralSource.id"
            >
              <td>
                <b class="mobile-tablet-only mr-2">ID:</b
                >{{ referralSource.id }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">First:</b
                >{{ referralSource.first }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Last:</b
                >{{ referralSource.last }}
              </td>
              <td class="text-right">
                <button
                  class="btn btn-sm btn-outline-blue"
                  :disabled="
                    referralSource.fetching || referralSourceClientsLoading
                  "
                  @click="displayClients(referralSource)"
                >
                  <i
                    class="fa fa-spin fa-circle-notch mr-1"
                    v-if="referralSource.fetching"
                  ></i>
                  Show Clients
                </button>
              </td>
              <td class="text-right">
                <router-link
                  :to="`/admin/referral-sources/${referralSource.id}`"
                >
                  <button class="btn btn-sm btn-black">Edit</button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <mugen-scroll :handler="loadMore" :should-handle="!busy && !loading">
          <alert
            v-if="busy"
            class="
              text-center
              p-2
              mb-0
              mobile-tablet-loadmore mobile-tablet-shadow-card
            "
            ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
            referral sources...</alert
          >
        </mugen-scroll>
        <div
          class="card-body pb-0"
          v-if="!loading && referralSources.length == 0"
        >
          <alert>
            No referral sources,
            <router-link to="/admin/referral-sources/create"
              >Add a new referral source here</router-link
            >
          </alert>
        </div>
      </div>
    </div>
    <modal
      class="referrer-clients-modal"
      name="referrer-clients-modal"
      transition="pop-out"
      :height="600"
      :width="1000"
    >
      <div class="position-relative modal-body">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeReferrerClientsModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="body-content modal-body-container">
          <ReferrerClientsModal />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MugenScroll from "vue-mugen-scroll";
import ReferrerClientsModal from "./ReferrerClientsModal.vue";
export default {
  components: {
    MugenScroll,
    ReferrerClientsModal,
  },
  data() {
    return {
      limit: 100,
      busy: false,
      searchContent: "",
      latestSearch: "",
    };
  },
  mounted() {
    this.getReferralSourcesList({
      limit: this.limit,
    });
  },
  computed: {
    ...mapState({
      referralSources: (state) =>
        state.referralSources.referralSources.pagination.data,
      current: (state) =>
        state.referralSources.referralSources.pagination.current,
      total: (state) => state.referralSources.referralSources.pagination.total,
      loading: (state) => state.referralSources.referralSources.loading,
      referralSourceClientsLoading: (state) =>
        state.referralSources.referralSourceClients.loading,
    }),
  },
  methods: {
    ...mapActions({
      getReferralSourcesList: "referralSources/get",
      getReferralSourceClients: "referralSources/getReferralSourceClients",
    }),
    getFilteredReferrer: function (page) {
      const payload = {
        limit: this.limit,
      };
      if (page) {
        payload.page = page;
      }
      if (this.searchContent) {
        payload.referrerName = this.searchContent;
      }
      this.getReferralSourcesList(payload).then(() => {
        this.busy = false;
      });
    },
    searchReferrerText: function () {
      if (this.latestSearch == this.searchContent) {
        return;
      }
      this.latestSearch = this.searchContent;
      this.getFilteredReferrer();
    },
    loadMore() {
      if (this.referralSources.length < this.total) {
        this.busy = true;
        this.getFilteredReferrer(this.current + 1);
      }
    },
    openReferrerClientsModal() {
      this.$modal.show("referrer-clients-modal");
    },
    closeReferrerClientsModal() {
      this.$modal.hide("referrer-clients-modal");
    },
    displayClients: function (source) {
      source.fetching = true;
      this.$forceUpdate();
      this.getReferralSourceClients({
        id: source.id,
        name: `${source.last}, ${source.first}`,
      }).then(() => {
        source.fetching = false;
        this.$forceUpdate();
        this.openReferrerClientsModal();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-icon,
.search-icon {
  z-index: 99;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 15px;
}
</style>
